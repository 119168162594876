import { optional, withDependencies } from '@wix/thunderbolt-ioc'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	CurrentRouteInfoSymbol,
	Experiments,
	ExperimentsSymbol,
	PlatformEnvDataProvider,
	ViewerModel,
	ViewerModelSym,
	WixBiSession,
	WixBiSessionSymbol
} from '@wix/thunderbolt-symbols'
import { ISessionManager, SessionManagerSymbol } from 'feature-session-manager'
import { ICurrentRouteInfo, UrlHistoryManagerSymbol, IUrlHistoryManager, RoutingLinkUtilsAPISymbol, IRoutingLinkUtilsAPI } from 'feature-router'
import { getBrowserLanguage, getBrowserReferrer, isSSR } from '@wix/thunderbolt-commons'
import _ from 'lodash'
import { IPopupsLinkUtilsAPI, PopupsLinkUtilsAPISymbol } from 'feature-popups'

export const locationEnvDataProvider = withDependencies(
	[UrlHistoryManagerSymbol],
	(urlHistoryManager: IUrlHistoryManager): PlatformEnvDataProvider => {
		return {
			get platformEnvData() {
				const rawUrl = urlHistoryManager.getParsedUrl().href
				return {
					location: {
						rawUrl
					}
				}
			}
		}
	}
)

export const windowEnvDataProvider = withDependencies(
	[BrowserWindowSymbol],
	(window: BrowserWindow): PlatformEnvDataProvider => ({
		platformEnvData: {
			window: {
				isSSR: isSSR(window),
				browserLocale: getBrowserLanguage(window)
			}
		}
	})
)

export const documentEnvDataProvider = withDependencies(
	[BrowserWindowSymbol],
	(window: BrowserWindow): PlatformEnvDataProvider => ({
		platformEnvData: {
			document: {
				referrer: getBrowserReferrer(window)
			}
		}
	})
)
export const biEnvDataProvider = withDependencies(
	[WixBiSessionSymbol, SessionManagerSymbol, ViewerModelSym, UrlHistoryManagerSymbol, ExperimentsSymbol, CurrentRouteInfoSymbol, optional(PopupsLinkUtilsAPISymbol)],
	(
		wixBiSession: WixBiSession,
		sessionManager: ISessionManager,
		viewerModel: ViewerModel,
		urlHistoryManager: IUrlHistoryManager,
		experiments: Experiments,
		currentRoutingInfo: ICurrentRouteInfo,
		popupsLinkUtilsAPI: IPopupsLinkUtilsAPI
	): PlatformEnvDataProvider => {
		let pageNumber = 0
		const { mode, rollout, fleetConfig } = viewerModel
		const bi = {
			..._.omit(wixBiSession, 'checkVisibility'),
			visitorId: sessionManager.getVisitorId(),
			siteMemberId: sessionManager.getSiteMemberId(),
			viewerVersion: process.env.browser ? window!.thunderboltVersion : (process.env.APP_VERSION as string),
			rolloutData: rollout,
			fleetConfig
		}

		return {
			// @ts-ignore
			get platformEnvData() {
				const pageId = currentRoutingInfo.getCurrentRouteInfo()?.pageId
				if (!pageId) {
					// platform init
					return {
						bi: {
							pageData: {
								pageNumber: 1
							},
							rolloutData: {},
							fleetConfig: {}
						}
					}
				}

				const currentLightboxId = popupsLinkUtilsAPI?.getCurrentOrPendingPopupId()
				const isLightbox = !!currentLightboxId

				if (!isLightbox) {
					pageNumber++
				}
				const { href, searchParams } = urlHistoryManager.getParsedUrl()

				const suppressBi = searchParams.has('suppressbi') && searchParams.get('suppressbi') !== 'false'

				const biPageData = {
					pageNumber,
					pageId: currentLightboxId || pageId,
					pageUrl: href,
					isLightbox
				}

				const muteBi = (mode.qa || suppressBi) && !experiments.sv_reportTrace

				const muteFedops = muteBi || isLightbox || pageNumber !== 1

				return {
					bi: {
						...bi,
						pageData: biPageData,
						muteBi,
						muteFedops
					}
				}
			}
		}
	}
)

export const routingEnvDataProvider = withDependencies(
	[RoutingLinkUtilsAPISymbol, CurrentRouteInfoSymbol, UrlHistoryManagerSymbol],
	(routingLinkUtilsAPI: IRoutingLinkUtilsAPI, currentRouteInfo: ICurrentRouteInfo): PlatformEnvDataProvider => {
		return {
			get platformEnvData() {
				const routeInfo = currentRouteInfo.getCurrentRouteInfo()
				return {
					router: {
						routingInfo: routingLinkUtilsAPI.getLinkUtilsRoutingInfo(),
						pageJsonFileName: routeInfo?.pageJsonFileName || '',
						routerReturnedData: routeInfo?.dynamicRouteData ?? null, // TODO why both ?
						routerData: routeInfo?.dynamicRouteData // TODO why both ?
					}
				}
			}
		}
	}
)
